@import '../../../../../../estee_base/scss/theme-bootstrap';
@import '../../../../scss/sections/variable';

$tray-header-color: #333;

.custom-collection-landing--tray-open {
  padding-bottom: 70px;
  .custom-collection-tray {
    display: block;
  }
}

.custom-collection-tray {
  display: none;
  height: 124px;
  margin: 0 -10px;
  overflow: hidden;
  @media (min-width: 1280px) {
    margin: 0;
  }
  &--sticky {
    .custom-collection-tray__wrapper {
      bottom: 43px;
      left: 0;
      position: fixed;
      width: 100%;
      z-index: 40;
    }
  }
  .slick-prev.slick-arrow {
    left: 15px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(135deg);
  }
  .slick-next.slick-arrow {
    right: 15px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .slick-arrow {
    background: none;
    border-color: #{$color-nero};
    border-style: solid;
    border-width: 0 1px 1px 0;
    height: 16px;
    margin: -8px 0 0 0;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    -webkit-transition: none;
    transition: none;
    width: 16px;
  }
  &__content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  &__wrapper {
    background-color: #{$color-white};
    border-bottom: 1px solid #{$color-light-gray};
    border-top: 1px solid #{$color-light-gray};
    height: 124px;
  }
  &-info {
    background-color: #{$color-light-navy};
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 70px;
    line-height: 1.5;
    padding: 10px;
    width: 100%;
    .button {
      font-family: $akzidenz;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
      background-color: #{$color-navy};
      border: 2px solid #{$color-navy};
      color: #{$color-coconut};
      height: auto;
      line-height: 1.5;
      margin-left: 10px;
      padding: 9px;
    }
    &__details {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
    &__total-items {
      font-family: $akzidenz;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0 0 5px;
    }
    &__subtotal {
      font-family: $akzidenz;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
    }
  }
  &__thumbs {
    display: none;
    .gift-thumb {
      &__details {
        overflow: hidden;
        padding-bottom: 26px;
      }
      &__product-name {
        color: $tray-header-color;
        font-family: $akzidenz;
        font-weight: 700;
        font-size: 0.75rem;
        letter-spacing: 0.1rem;
        line-height: 1.333333;
        text-transform: uppercase;
        max-height: 30px;
        overflow: hidden;
      }
      &__product-sub-name {
        font-family: $optimaregular;
        font-size: 0.875rem;
        line-height: 1.285714;
        margin: 0;
        max-height: 3.375rem;
        overflow: hidden;
      }
      &__price {
        bottom: 8px;
        font-size: 0.75rem;
        line-height: 1.333333;
        margin: 0;
        position: absolute;
      }
    }
  }
}

@media (min-width: $tablet-width-min) {
  .custom-collection-landing--tray-open {
    padding-bottom: 0;
  }
  .custom-collection-tray {
    &-info {
      height: auto;
      padding: 20px;
      width: 40%;
    }
    &__thumbs {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin: 0;
      padding: 0 2em;
      position: relative;
      white-space: nowrap;
      width: 60%;
      .slick-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .slick-track {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .gift-thumbs__item {
        display: inline-block;
        height: 124px;
        padding: 10px 5px;
        position: relative;
        white-space: normal;
      }
      .gift-thumb__thumb {
        float: left;
        height: 90px;
        margin-right: 15px;
      }
      .gift-thumb__delete {
        background: #{$color-black};
        border: 5px solid #{$color-white};
        border-radius: 50%;
        height: 25px;
        left: 0;
        padding: 0;
        position: absolute;
        text-indent: -999em;
        top: 0;
        width: 25px;
        &::before {
          background-color: #{$color-white};
          content: '';
          height: 60%;
          left: 7px;
          position: absolute;
          top: 3px;
          width: 1px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &::after {
          background-color: #{$color-white};
          content: '';
          height: 60%;
          left: 7px;
          position: absolute;
          top: 3px;
          width: 1px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media (min-width: $product-wide) {
  .custom-collection-tray {
    &-info {
      width: 30%;
    }
    &__thumbs {
      width: 70%;
    }
  }
}
